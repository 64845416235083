
import { Component } from 'vue-property-decorator';
import AttendanceToolbar from './AttendanceToolbar.vue';

@Component({
  components: {
    AttendanceToolbar
  }
})
export default class AttendanceBottomToolbar extends AttendanceToolbar {
  get isAllAbsent() {
    return this.localAttendanceComponent.isAllAbsent || false;
  }

  get setAllAbsent() {
    return this.localAttendanceComponent.setAllAbsent || this.emptyFunction;
  }

  get allTardyAbsentButtonClass() {
    return this.localAttendanceComponent.allTardyAbsentButtonClass || '';
  }

  get isAllTardy() {
    return this.localAttendanceComponent.isAllTardy || false;
  }

  get setAllTardy() {
    return this.localAttendanceComponent.setAllTardy || this.emptyFunction;
  }

  get isAllPresent() {
    return this.localAttendanceComponent.isAllPresent || false;
  }

  get allPresentButtonClass() {
    return this.localAttendanceComponent.allPresentButtonClass || '';
  }

  get setAllPresent() {
    return this.localAttendanceComponent.setAllPresent || this.emptyFunction;
  }

  get clearAllButtonClass() {
    return this.localAttendanceComponent.clearAllButtonClass || '';
  }

  get isAttendanceClear() {
    return this.localAttendanceComponent.isAttendanceClear || '';
  }

  get clearAttendance() {
    return this.localAttendanceComponent.clearAttendance || this.emptyFunction;
  }

  get controlsClass() {
    return this.localAttendanceComponent.controlsClass || 'ml-16 mt-4';
  }
}
